import { IUserPermissionModel } from "./user-permission.model";
import { IContactModel } from "@models/contacts/contact.model";

export interface IUserModel {
	userId: number;
	uuid: string;
	companyId: number;
	documentFolderUuid: string;
	employeeRoleId: number;
	employeeRoleName: string;
	payPeriodDefinitionId: number;
	payPeriodDefinitionName: string;
	userName: string;
	startDate: Date;
	terminationDate: Date;
	notes: string;
	profilePictureUrl: string;
	showOnSchedule: boolean;
	quickbooksSalesRepCode: string;
	syncToQuickbooks: boolean;
	quickbooksName: string;
	quickbooksId: string;
	workersCompCode: string;
	medicalInfo: string;
	isAdmin: boolean;
	isEstimator: boolean;
	isSpecialtyManager: boolean;
	isProductionManager: boolean;
	isBusinessDevelopment: boolean;
	isAccountsPayable: boolean;
	isAccountsReceivable: boolean;
	isServiceTech: boolean;
	is1099: boolean;
	textChatInitialAttendant: boolean;
	active: boolean;
	contact: IContactModel;
	userPermissions: IUserPermissionModel[];
}

export class UserModel implements IUserModel {
	userId: number;
	uuid: string;
	companyId: number;
	documentFolderUuid: string;
	employeeRoleId: number;
	employeeRoleName: string;
	payPeriodDefinitionId: number;
	payPeriodDefinitionName: string;
	userName: string;
	startDate: Date;
	terminationDate: Date;
	notes: string;
	profilePictureUrl: string;
	showOnSchedule: boolean;
	quickbooksSalesRepCode: string;
	syncToQuickbooks: boolean;
	quickbooksName: string;
	quickbooksId: string;
	workersCompCode: string;
	medicalInfo: string;
	isAdmin: boolean;
	isEstimator: boolean;
	isSpecialtyManager: boolean;
	isProductionManager: boolean;
	isBusinessDevelopment: boolean;
	isAccountsPayable: boolean;
	isAccountsReceivable: boolean;
	isServiceTech: boolean;
	is1099: boolean;
	textChatInitialAttendant: boolean;
	active: boolean;
	contact: IContactModel;
	userPermissions: IUserPermissionModel[];
}
