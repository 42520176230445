import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ICompanyInfoModel } from "@models/companies/company-info.model"
import { ICompanySettingsModel } from "@models/companies/company-settings.model";
import { IUserInfoModel } from "@models/users/user-info.model";
import { IUserSettingsModel } from "@models/users/user-settings.model";
import { IUserPermissionModel } from "@models/users/user-permission.model";
import * as moment from "moment";
import { IUserBadgesModel } from "@models"; 

class CacheChecksums {
	userInfoChecksum: string;
	userSettingsChecksum: string;
	companyInfoChecksum: string;
	companySettingsChecksum: string;
	lookupsChecksum: string;
}

@Injectable()
export class GlobalsService {
	public static appVersion = "6.4.10"; 
	public static isDebug: boolean = false;
	public static isLoggedIn: boolean;
	public static isOnlinePayments: boolean;
	public static isAdmin: boolean;
	public static isAndroid: boolean;
	public static isiOS: boolean;
	public static isDesktop: boolean;
	public static isStaging: boolean = false;
	public static isRC: boolean = false;
	public static pushNotificationToken: string;
	public static jwtToken: string = localStorage.getItem("jwt_token");
	public static docsUrl: string = "https://docs.jobsight.com";
	public static companyInfo: ICompanyInfoModel = {} as any;
	public static companySettings: ICompanySettingsModel = {} as any;
	public static userInfo: IUserInfoModel = {} as any;
	public static userSettings: IUserSettingsModel = {} as any;
	public static userBadges: IUserBadgesModel = {} as any;
	public static cacheChecksums: CacheChecksums;
	public static initialPage: string;

	constructor(private http: HttpClient) { }

	setDebug() {
		console.info("Set Debug")
		GlobalsService.isDebug = true;
		GlobalsService.docsUrl = "http://devdocs.jobsight.com";
	}

	setStaging() {
		console.info("Set Staging")
		GlobalsService.isStaging = true;
	}

	setRC() {
		console.info("Set RC")
		GlobalsService.isRC = true;
	}

	public static get hostName(): string {
		if (GlobalsService.isStaging)
			return 'https://staging.jobsight.com';
		if (GlobalsService.isRC)
			return 'https://rc.jobsight.com';
		else if (GlobalsService.isDebug)
			return 'http://dev.jobsight.com'
		else
			return 'https://app.jobsight.com'
	}

	public static get apiUrl(): string {
		if (GlobalsService.isStaging)
			return 'https://stagingapi.jobsight.com';
		if (GlobalsService.isRC)
			return 'https://rcapi.jobsight.com';
		else if (GlobalsService.isDebug)
			return 'http://devapi.jobsight.com'
		//return 'http://localhost:44315'
		else
			return 'https://api.jobsight.com'
	}

	async isLoggedIn(): Promise<any> {
		try {
			if (window.location.href.toLowerCase().indexOf("/login") >= 0) {
				GlobalsService.isLoggedIn = false;
				return Promise.resolve(false);
			}

			GlobalsService.isLoggedIn = false;
			if (GlobalsService.jwtToken) {
				try {
					GlobalsService.isLoggedIn = <boolean>(await this.http.get("/init/validateLogin?jwtToken=" + GlobalsService.jwtToken).toPromise());

					//setTimeout(async () => {
					//	if (GlobalsService.isLoggedIn == false) {
					//		console.info("Clearing Token");
					//		localStorage.removeItem("jwt_token");
					//		window.location.href = "/";
					//	}
					//}, 10000);
				}
				catch (err) {
					localStorage.removeItem("jwt_token");
					window.location.href = "/";
				}
			}

			return Promise.resolve(GlobalsService.isLoggedIn);
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	async getCacheChecksums(): Promise<any> {
		GlobalsService.cacheChecksums = <CacheChecksums>(await this.http.get('/init/getCacheChecksums').toPromise());
	}

	async init(isMobile: boolean): Promise<any> {
		try {
			let data: any[] = await Promise.all([this.getUserInfo(), this.getUserSettings(isMobile), this.getCompanyInfo(), this.getCompanySettings(), this.getUserBadges()]);
			GlobalsService.userInfo = <IUserInfoModel>data[0];
			GlobalsService.userSettings = <IUserSettingsModel>data[1];
			GlobalsService.companyInfo = <ICompanyInfoModel>data[2];
			GlobalsService.companySettings = <ICompanySettingsModel>data[3];
			GlobalsService.userBadges = <IUserBadgesModel>data[4];

			GlobalsService.companySettings.firstPayPeriodStartDate = moment("11/11/2019", "MM/DD/YYYY").toDate();
			return Promise.resolve();
		}
		catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	}

	private async getUserInfo(): Promise<IUserInfoModel> {
		return new Promise<IUserInfoModel>(async (resolve) => {
			let userInfo: IUserInfoModel;
			const userInfoCacheChecksum = localStorage.getItem("USER_INFO_CACHE_CHECKSUM") || '';
			if (userInfoCacheChecksum === GlobalsService.cacheChecksums.userInfoChecksum) {
				const userInfoJSON = localStorage.getItem("USER_INFO");
				userInfo = JSON.parse(userInfoJSON);
			}
			else {
				userInfo = <IUserInfoModel>(await this.http.get("/init/getCurrentUserInfo").toPromise());
				const userInfoJSON = JSON.stringify(userInfo);
				localStorage.setItem("USER_INFO", userInfoJSON);
				localStorage.setItem("USER_INFO_CACHE_CHECKSUM", GlobalsService.cacheChecksums.userInfoChecksum);

			}

			resolve(userInfo);
		});
	}

	private async getUserSettings(isMobile: boolean): Promise<IUserSettingsModel> {
		return new Promise<IUserSettingsModel>(async (resolve) => {
			let userSettings: IUserSettingsModel;
			const userSettingsCacheChecksum = localStorage.getItem("USER_SETTINGS_CACHE_CHECKSUM") || '';
			if (userSettingsCacheChecksum === GlobalsService.cacheChecksums.userSettingsChecksum) {
				const userSettingsJSON = localStorage.getItem("USER_SETTINGS");
				userSettings = JSON.parse(userSettingsJSON);
			}
			else {
				userSettings = <IUserSettingsModel>(await this.http.get(`/init/getCurrentUserSettings?isMobile=${isMobile}`).toPromise());
				const userSettingsJSON = JSON.stringify(userSettings);
				localStorage.setItem("USER_SETTINGS", userSettingsJSON);
				localStorage.setItem("USER_SETTINGS_CACHE_CHECKSUM", GlobalsService.cacheChecksums.userSettingsChecksum);
			}

			resolve(userSettings);
		});
	}

	private async getCompanyInfo(): Promise<ICompanyInfoModel> {
		return new Promise<ICompanyInfoModel>(async (resolve) => {
			let companyInfo: ICompanyInfoModel;
			const companyInfoCacheChecksum = localStorage.getItem("COMPANY_INFO_CACHE_CHECKSUM") || '';
			if (companyInfoCacheChecksum === GlobalsService.cacheChecksums.companyInfoChecksum) {
				const companyInfoJSON = localStorage.getItem("COMPANY_INFO");
				companyInfo = JSON.parse(companyInfoJSON);

			}
			else {
				companyInfo = <ICompanyInfoModel>(await this.http.get("/init/getCurrentCompanyInfo").toPromise());
				const companyInfoJSON = JSON.stringify(companyInfo);
				localStorage.setItem("COMPANY_INFO", companyInfoJSON);
				localStorage.setItem("COMPANY_INFO_CACHE_CHECKSUM", GlobalsService.cacheChecksums.companyInfoChecksum);
			}

			resolve(companyInfo);
		});
	}

	private async getCompanySettings(): Promise<ICompanySettingsModel> {
		return new Promise<ICompanySettingsModel>(async (resolve) => {
			let companySettings: ICompanySettingsModel;
			const companySettingsCacheChecksum = localStorage.getItem("COMPANY_SETTINGS_CACHE_CHECKSUM") || '';
			if (companySettingsCacheChecksum === GlobalsService.cacheChecksums.companySettingsChecksum) {
				const companySettingsJSON = localStorage.getItem("COMPANY_SETTINGS");
				companySettings = JSON.parse(companySettingsJSON);
			}
			else {
				companySettings = <ICompanySettingsModel>(await this.http.get("/init/getCurrentCompanySettings").toPromise());
				const companySettingsJSON = JSON.stringify(companySettings);
				localStorage.setItem("COMPANY_SETTINGS", companySettingsJSON);
				localStorage.setItem("COMPANY_SETTINGS_CACHE_CHECKSUM", GlobalsService.cacheChecksums.companySettingsChecksum);
			}

			resolve(companySettings);
		});
	}

	private async getUserBadges(): Promise<IUserBadgesModel> {
		return new Promise<IUserBadgesModel>(async (resolve) => {
			const userBadges = <IUserBadgesModel>(await this.http.get("/init/getUserBadges").toPromise());

			resolve(userBadges);
		});
	}

	public static checkPermission(feature: string, actions: string): boolean {
		let permissionFeature: IUserPermissionModel[] = GlobalsService.userInfo.userPermissions.filter((p) => { return p.feature.toLocaleLowerCase() === feature.toLocaleLowerCase() });

		let granted = false;

		let actionsArray = actions.split('|');
		actionsArray.forEach((a) => {
			let featureAction: IUserPermissionModel = permissionFeature.find((f) => { return f.action.toLocaleLowerCase() === a.toLocaleLowerCase() });
			if (!featureAction) {
				alert("Feature: " + feature + " | Action: " + a + " was not found.");
				return false;
			}

			if (featureAction.granted === true)
				granted = true;
		})

		return granted;
	}
}

