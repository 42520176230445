import { ICustomerModel } from "@models/customers/customer.model";
import { IContactModel } from "@models/contacts/contact.model";
import { IServiceAgreementModel } from '../service-agreements/service-agreement.model';
import { IMilestoneModel } from "../milestones/milestone.model";

export interface IJobModel {
	jobId: number;
	uuid: string;
	jobGroupId: string;
	version: number;
	jobSeq: number;
	photosFolderUuid: string;
	documentsFolderUuid: string;
	primaryCustomer: ICustomerModel;
	billingCustomer: ICustomerModel;
	billingIsSameAsPrimary: boolean;
	jobName: string;
	propertyType: string;
	deductibleAmount?: number;
	serviceAgreementSignature: string;
	serviceAgreementDate: string;
	serviceAgreementIPAddress: string;
	scratchPad: string;
	jobStatusDescription: string;
	jobStatusId: number;
	jobCustomStatus: string;
	jobCustomStatusId: number;
	notAcceptedReason: string;
	notAcceptedReasonId?: number;
	estimatorName: string;
	estimatorUserId?: number;
	damageTypeId?: number;
	damageTypeName: string;
	productionManagerName: string;
	productionManagerUserId?: number;
	specialtyManagerName: string;
	specialtyManagerUserId?: number;
	referralAccountId?: number;
	referralContactId?: number;
	referralUuid: string;
	referralDisplayName: string;
	insuranceCarrierId?: number;
	insuranceCarrierName: string;
	insuranceCarrierContact: IContactModel;
	insuranceAdjusterId?: number;
	insuranceAdjusterName: string;
	insuranceAdjusterContact: IContactModel;
	isDeskAdjuster: boolean;
	deskAdjusterInfo: string;	
	insuranceAgentId?: number;
	insuranceAgentName: string;
	insuranceAgentContact: IContactModel;
	claimNumber: string;
	lienNumber: string;
	catCode: string;
	dateOfLoss?: Date;
	yearBuilt: string;
	isSelfPay: boolean;
	serviceAgreementNotes: string;
	estimatesCount: number;
	workOrdersCount: number;
	invoicesCount: number;
	paymentsCount: number;
	placedEquipmentCount: number;
	mitigationRoomCount: number;
	photosCount: number;
	documentsCount: number;
	serviceAgreementsCount: number;
	missingForms: number;
	hasServiceAgreement: boolean;
	defaultServiceAgreement: IServiceAgreementModel;
	syncToQuickbooks: boolean;
	quickbooksId: string;
	customQuickbooksName: boolean;
	quickbooksName: string;
	xactTransactionId: string;
	xactSyncStatus: string;

	active: boolean;
	amountDue: number;
	contacts: IContactModel[];
	milestones: IMilestoneModel[];
	serviceAgreements: IServiceAgreementModel[];
	changeOrders: IServiceAgreementModel[];
	certificateOfCompletions: IServiceAgreementModel[];
}

export class JobModel implements IJobModel
{
	jobId: number;
	uuid: string;
	jobGroupId: string;
	version: number;
	jobSeq: number;
	photosFolderUuid: string;
	documentsFolderUuid: string;
	primaryCustomer: ICustomerModel;
	billingCustomer: ICustomerModel;
	billingIsSameAsPrimary: boolean;
	jobName: string;
	propertyType: string;
	deductibleAmount?: number;
	serviceAgreementSignature: string;
	serviceAgreementDate: string;
	serviceAgreementIPAddress: string;
	scratchPad: string;
	jobStatusDescription: string;
	jobStatusId: number;
	jobCustomStatus: string;
	jobCustomStatusId: number;
	notAcceptedReason: string;
	notAcceptedReasonId?: number;
	estimatorName: string;
	estimatorUserId?: number;
	damageTypeId?: number;
	damageTypeName: string;
	productionManagerName: string;
	productionManagerUserId?: number;
	specialtyManagerName: string;
	specialtyManagerUserId?: number;
	referralAccountId?: number;
	referralContactId?: number;
	referralUuid: string;
	referralDisplayName: string;
	insuranceCarrierId?: number;
	insuranceCarrierName: string;
	insuranceCarrierContact: IContactModel;
	insuranceAdjusterId?: number;
	insuranceAdjusterName: string;
	insuranceAdjusterContact: IContactModel;
	isDeskAdjuster: boolean;
	deskAdjusterInfo: string;
	insuranceAgentId?: number;
	insuranceAgentName: string;
	insuranceAgentContact: IContactModel;
	claimNumber: string;
	lienNumber: string;
	catCode: string;
	dateOfLoss?: Date;
	yearBuilt: string;
	isSelfPay: boolean;
	serviceAgreementNotes: string;
	estimatesCount: number;
	workOrdersCount: number;
	invoicesCount: number;
	paymentsCount: number;
	placedEquipmentCount: number;
	mitigationRoomCount: number;
	photosCount: number;
	documentsCount: number;
	serviceAgreementsCount: number;
	missingForms: number;
	hasServiceAgreement: boolean;
	defaultServiceAgreement: IServiceAgreementModel;
	syncToQuickbooks: boolean;
	quickbooksId: string;
	customQuickbooksName: boolean;
	quickbooksName: string;
	xactTransactionId: string;
	xactSyncStatus: string;

	active: boolean;
	amountDue: number;
	contacts: IContactModel[];
	milestones: IMilestoneModel[];
	serviceAgreements: IServiceAgreementModel[];
	changeOrders: IServiceAgreementModel[];
	certificateOfCompletions: IServiceAgreementModel[];
}
