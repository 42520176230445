import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";
import { IBusinessDevelopmentAccountModel, IBusinessDevelopmentContactModel, IJobGridModel, IBusinessDevelopmentGridModel, IBusinessDevelopmentAccountNoteModel, IBusinessDevelopmentContactRoleModel } from "@models";
import { IMobileBusinessDevelopmentListSettingsModel, MobileBusinessDevelopmentListSettingsModel } from "../models/business-development/mobile-business-development-list-settings.model";

@Injectable()
export class BusinessDevelopmentService {
	constructor(private httpService: HttpService) { }

	async getBusinessDevelopmentAccountForGrid(businessDevelopmentAccountId: number): Promise<IBusinessDevelopmentGridModel> {
		const params = {
			businessDevelopmentAccountId
		}
		return this.httpService.get("/businessDevelopment/getBusinessDevelopmentAccountForGrid", params);
	}

	async getBusinessDevelopmentAccountsForInfiniteScroll(pageCount: number, searchString: string, showContacts: boolean, showMyAccountsOnly: boolean): Promise<IBusinessDevelopmentGridModel[]> {
		const settingsModel: IMobileBusinessDevelopmentListSettingsModel = JSON.parse(localStorage.getItem("BUSINESS_DEVELOPMENT_LIST_SETTINGS")) || new MobileBusinessDevelopmentListSettingsModel();

		const params = {
			pageCount,
			searchString,
			showContacts,
			showMyAccountsOnly,
			sortBy: settingsModel.sortBy
		}
		return this.httpService.get("/businessDevelopment/getBusinessDevelopmentAccountsForInfiniteScroll", params);
	}

	async getBusinessDevelopmentAccount(businessDevelopmentAccountId: number): Promise<IBusinessDevelopmentAccountModel> {
		const params = {
			businessDevelopmentAccountId
		}
		return this.httpService.get("/businessDevelopment/getBusinessDevelopmentAccount", params);
	}

	async addBusinessDevelopmentAccount(businessDevelopmentAccount: IBusinessDevelopmentAccountModel): Promise<IBusinessDevelopmentAccountModel> {
		return this.httpService.post("/businessDevelopment/addBusinessDevelopmentAccount", businessDevelopmentAccount);
	}

	async updateBusinessDevelopmentAccount(businessDevelopmentAccount: IBusinessDevelopmentAccountModel): Promise<IBusinessDevelopmentAccountModel> {
		return this.httpService.post("/businessDevelopment/updateBusinessDevelopmentAccount", businessDevelopmentAccount);
	}

	async getBusinessDevelopmentContact(businessDevelopmentContactId: number): Promise<IBusinessDevelopmentContactModel> {
		const params = {
			businessDevelopmentContactId
		}
		return this.httpService.get("/businessDevelopment/getBusinessDevelopmentContact", params);
	}

	async addBusinessDevelopmentContact(businessDevelopmentContact: IBusinessDevelopmentContactModel): Promise<IBusinessDevelopmentContactModel> {
		return this.httpService.post("/businessDevelopment/addBusinessDevelopmentContact", businessDevelopmentContact);
	}

	async updateBusinessDevelopmentContact(businessDevelopmentContact: IBusinessDevelopmentContactModel): Promise<IBusinessDevelopmentContactModel> {
		return this.httpService.post("/businessDevelopment/updateBusinessDevelopmentContact", businessDevelopmentContact);
	}

	async getJobsForBusinessDevelopmentAccount(businessDevelopmentAccountId: number): Promise<IJobGridModel[]> {
		const params = {
			businessDevelopmentAccountId
		}
		return this.httpService.get("/businessDevelopment/getJobsForBusinessDevelopmentAccount", params);
	}

	async deleteBusinessDevelopmentAccount(businessDevelopmentAccountId: number) {
		const params = {
			businessDevelopmentAccountId
		}
		return this.httpService.delete("/businessDevelopment/deleteBusinessDevelopmentAccount", params);
	}

	async deleteBusinessDevelopmentContact(businessDevelopmentContactId: number) {
		const params = {
			businessDevelopmentContactId
		}
		return this.httpService.delete("/businessDevelopment/deleteBusinessDevelopmentContact", params);
	}

	async getBusinessDevelopmentUserNotes(businessDevelopmentAccountId: number): Promise<IBusinessDevelopmentAccountNoteModel[]> {
		const params = {
			businessDevelopmentAccountId
		}
		return this.httpService.get("/businessDevelopment/getBusinessDevelopmentUserNotes", params);
	}

	async togglePinnedBusinessDevelopmentAccount(businessDevelopmentAccountId: number): Promise<boolean> {
		return this.httpService.patch(`/businessDevelopment/togglePinnedBusinessDevelopmentAccount?businessDevelopmentAccountId=${businessDevelopmentAccountId}`);
	}

	async togglePinnedBusinessDevelopmentContact(businessDevelopmentContactId: number): Promise<boolean> {
		return this.httpService.patch(`/businessDevelopment/togglePinnedBusinessDevelopmentContact?businessDevelopmentContactId=${businessDevelopmentContactId}`);
	}
	async recordVisit(businessDevelopmentAccountId: number, visitDate: Date, noteText: string): Promise<any> {
		const params = {
			businessDevelopmentAccountId: businessDevelopmentAccountId,
			visitDate: visitDate,
			noteText: noteText

		};

		return this.httpService.post("/businessDevelopment/recordVisitNote", params);
	}
}
