import { Injectable } from "@angular/core";
import { IRoomDesignWallModel, Coordinates } from "@models";
import { error } from "console";
import { min } from "rxjs";
import { UtilsService } from "./utils/utils.service";

@Injectable()
export class RoomDesignService {
	constructor() {
	}

	snapStart(wall: IRoomDesignWallModel, x: number, y: number, snapPixels: number = 25): boolean {
		return (x >= (wall.coordsP1X - snapPixels) && x <= (wall.coordsP1X + snapPixels) && y >= (wall.coordsP1Y - snapPixels) && y <= (wall.coordsP1Y + snapPixels));
	}

	snapEnd(wall: IRoomDesignWallModel, x: number, y: number, snapPixels: number = 25): boolean {
		return (x >= (wall.coordsP2X - snapPixels) && x <= (wall.coordsP2X + snapPixels) && y >= (wall.coordsP2Y - snapPixels) && (y <= wall.coordsP2Y + snapPixels));
	}

	areCoordsInWall(wall: IRoomDesignWallModel, x: number, y: number): boolean {
		const padding: number = 25;
		let isInWall = false;

		if (!wall.lineCoordinates)
			wall.lineCoordinates = this.getLineCoordinates(wall);

		// Using some will short circut the foreach
		wall.lineCoordinates.some(coord => {
			if (x >= (coord.x - padding) && x <= (coord.x + padding) && y >= (coord.y - padding) && y <= (coord.y + padding)) {
				isInWall = true;
				return true;
			}
		});

		return isInWall;
	}

	swapCoordinates(wall: IRoomDesignWallModel): IRoomDesignWallModel {
		const tempP1X = wall.coordsP1X;
		const tempP1Y = wall.coordsP1Y;
		wall.coordsP1X = wall.coordsP2X;
		wall.coordsP1Y = wall.coordsP2Y;
		wall.coordsP2X = tempP1X;
		wall.coordsP2Y = tempP1Y;
		wall.lineCoordinates = this.getLineCoordinates(wall);

		return wall;
	}

	getLineCoordinates(wall: IRoomDesignWallModel,): Coordinates[] {
		const coordinatesArray: Coordinates[] = [];

		let maxLoops = 10000;

		let x1 = wall.coordsP1X;
		let y1 = wall.coordsP1Y
		const x2 = wall.coordsP2X;
		const y2 = wall.coordsP2Y;
		// Define differences and error check
		const dx = Math.abs(x2 - x1);
		const dy = Math.abs(y2 - y1);
		const sx = (x1 < x2) ? 1 : -1;
		const sy = (y1 < y2) ? 1 : -1;
		let err = dx - dy;
		// Set first coordinates
		coordinatesArray.push(new Coordinates(x1, y1));
		// Main loop
		while (!((x1 >= x2) && (y1 >= y2))) {
			if (maxLoops-- < 0) {
				console.error("Max loops");
				break;
			}

			var e2 = err << 1;
			if (e2 >= -dy) {
				err -= dy;
				x1 += sx;
			}
			if (e2 <= dx) {
				err += dx;
				y1 += sy;
			}
			// Set coordinates
			coordinatesArray.push(new Coordinates(x1, y1));
		}
		// Return the result
		return coordinatesArray;
	}

	findClosestEndPoint(x: number, y: number, coordinates: Coordinates[], maxDistance: number = 999999): Coordinates {
		let returnModel: Coordinates = null;
		let shortestDistance: number = 999999;

		// First see if we have an exact match
		const exactMatch = coordinates.find(coord => coord.x === x && coord.y === y);
		if (exactMatch)
			return exactMatch;

		coordinates.forEach(coordinate => {
			// Ignore the current wall
			const distance = this.getDistance(x, y, coordinate.x, coordinate.y);
			if (distance < maxDistance && distance < shortestDistance) {
				shortestDistance = distance;
				returnModel = coordinate;
			}
		});

		return returnModel;
	}

	getDistance(x1: number, y1: number, x2: number, y2: number): number {
		const distance = UtilsService.round(Math.sqrt(Math.pow((x1 - x2), 2) + Math.pow((y1 - y2), 2)), 0);
		return distance;
	}

	reorderWalls(walls: IRoomDesignWallModel[]): IRoomDesignWallModel[] {
    let returnWalls: IRoomDesignWallModel[] = [];

    if (!walls || walls.length === 0) return returnWalls;

    let topLeftWall: IRoomDesignWallModel | null = null;
    let minDistance: number = Infinity;

    // Find the wall with the closest endpoint to (0,0)
    walls.forEach(wall => {
        const lengthP1 = this.getDistance(0, 0, wall.coordsP1X, wall.coordsP1Y);
        if (lengthP1 < minDistance) {
            minDistance = lengthP1;
            topLeftWall = wall;
        }

        const lengthP2 = this.getDistance(0, 0, wall.coordsP2X, wall.coordsP2Y);
        if (lengthP2 < minDistance) {
            minDistance = lengthP2;
            topLeftWall = wall;
        }
    });

    if (!topLeftWall) {
        return returnWalls; // Handle case where no wall is found
    }

    // Ensure topLeftWall's P1 is on the left
    if (topLeftWall.coordsP1X > topLeftWall.coordsP2X) {
        topLeftWall = this.swapCoordinates(topLeftWall);
    }

    returnWalls.push({ ...topLeftWall }); // Clone before adding
    let lastWall = { ...topLeftWall }; // Clone to prevent modification

    let remainingWalls = walls.filter(w => w.uuid !== topLeftWall!.uuid);

    for (let i = 0; i < walls.length - 1; i++) {
        let currentWall: IRoomDesignWallModel | null = null;

        // Find the closest endpoint for P1
        const p1Coords = remainingWalls.map(w => new Coordinates(w.coordsP1X, w.coordsP1Y));
        const closestP1Point = this.findClosestEndPoint(lastWall.coordsP2X, lastWall.coordsP2Y, p1Coords, 100);

        if (closestP1Point) {
            currentWall = remainingWalls.find(w => w.coordsP1X === closestP1Point.x && w.coordsP1Y === closestP1Point.y) || null;
        } else {
            // Check P2 if no P1 match is found
            const p2Coords = remainingWalls.map(w => new Coordinates(w.coordsP2X, w.coordsP2Y));
            const closestP2Point = this.findClosestEndPoint(lastWall.coordsP2X, lastWall.coordsP2Y, p2Coords, 100);

            if (closestP2Point) {
                currentWall = remainingWalls.find(w => w.coordsP2X === closestP2Point.x && w.coordsP2Y === closestP2Point.y) || null;
                
                if (currentWall) {
                    currentWall = this.swapCoordinates(currentWall);
                }
            }
        }

        if (currentWall) {
            returnWalls.push({ ...currentWall }); // Clone before adding
            remainingWalls = remainingWalls.filter(w => w.uuid !== currentWall!.uuid);
            lastWall = { ...currentWall }; // Update lastWall as a clone
        } else {
            return [...returnWalls, ...remainingWalls]; // Return what we have so far
        }
    }

    return returnWalls;
}
}

