import { Injectable } from "@angular/core";
import { DomSanitizer } from '@angular/platform-browser';
import { HttpService } from "./utils/http.service";
import { UtilsService } from "./utils/utils.service";
import { GlobalsService } from "./utils/globals.service";
import { FunctionLockService } from "./utils/function-lock.service";
import { IEmailerModel, INoteModel } from "@models";
import * as moment from "moment";

@Injectable()
export class NotesService {
	noteType: string;
	referenceId: number;
	showSystemNotes: boolean;

	private maxNotes: number = 25;

	constructor(private httpService: HttpService,
		private functionLockService: FunctionLockService,
		private sanitizer: DomSanitizer
	) { }

	async refreshNotes(notes: INoteModel[]): Promise<INoteModel[]> {
		await this.functionLockService.lock("REFRESH_NOTES");

		// Don't want to leave these timeouts running
		if (notes)
			notes.forEach(note => clearTimeout(note.editTimer));

		let getUrl = '';
		switch (this.noteType) {
			case "BusinessDevelopment":
				if (!!this.showSystemNotes === true)
					getUrl = "/businessDevelopment/getBusinessDevelopmentSystemNotes?businessDevelopmentAccountId=" + this.referenceId + "&maxnotes=" + this.maxNotes;
				else
					getUrl = "/businessDevelopment/getBusinessDevelopmentUserNotes?businessDevelopmentAccountId=" + this.referenceId + "&maxnotes=" + this.maxNotes;
				break;

			case "Commission":
				if (!!this.showSystemNotes === true)
					getUrl = "/commissions/getCommissionSystemNotes?commissionId=" + this.referenceId + "&maxnotes=" + this.maxNotes;
				else
					getUrl = "/Commissions/getCommissionUserNotes?commissionId=" + this.referenceId + "&maxnotes=" + this.maxNotes;
				break;

			case "Customer":
				if (!!this.showSystemNotes === true)
					getUrl = "/customers/getCustomerSystemNotes?customerId=" + this.referenceId + "&maxnotes=" + this.maxNotes;
				else
					getUrl = "/customers/getCustomerUserNotes?customerId=" + this.referenceId + "&maxnotes=" + this.maxNotes;
				break;

			case "Equipment":
				if (!!this.showSystemNotes === true)
					getUrl = "/equipment/getEquipmentSystemNotes?equipmentId=" + this.referenceId + "&maxnotes=" + this.maxNotes;
				else
					getUrl = "/equipment/getEquipmentUserNotes?equipmentId=" + this.referenceId + "&maxnotes=" + this.maxNotes;
				break;

			case "InvoiceFinancial":
			case "JobFinancial":
					getUrl = "/jobs/getFinancialNotes?jobId=" + this.referenceId + "&maxnotes=" + this.maxNotes;
				break;

			case "GlobalAdmin":
				if (!!this.showSystemNotes === true)
					getUrl = "/globalAdmin/getGlobalAdminSystemNotes?globalAdminCompanyId=" + this.referenceId + "&maxnotes=" + this.maxNotes;
				else
					getUrl = "/globalAdmin/getGlobalAdminUserNotes?globalAdminCompanyId=" + this.referenceId + "&maxnotes=" + this.maxNotes;
				break;

			case "HR":
				if (!!this.showSystemNotes === true)
					getUrl = "/users/getHRSystemNotes?employeeId=" + this.referenceId + "&maxnotes=" + this.maxNotes;
				else
					getUrl = "/users/getHRUserNotes?employeeId=" + this.referenceId + "&maxnotes=" + this.maxNotes;
				break;

			case "Invoice":
				if (!!this.showSystemNotes === true)
					getUrl = "/invoices/getInvoiceSystemNotes?invoiceId=" + this.referenceId + "&maxnotes=" + this.maxNotes;
				else
					getUrl = "/invoices/getInvoiceUserNotes?invoiceId=" + this.referenceId + "&maxnotes=" + this.maxNotes;
				break;

			case "Job":
				if (!!this.showSystemNotes === true)
					getUrl = "/jobs/getJobSystemNotes?jobId=" + this.referenceId + "&maxnotes=" + this.maxNotes;
				else
					getUrl = "/jobs/getJobUserNotes?jobId=" + this.referenceId + "&maxnotes=" + this.maxNotes;
				break;

			case "Payable":
				if (!!this.showSystemNotes === true)
					getUrl = "/payables/getPayableSystemNotes?payableId=" + this.referenceId + "&maxnotes=" + this.maxNotes;
				else
					getUrl = "/payables/getPayableUserNotes?payableId=" + this.referenceId + "&maxnotes=" + this.maxNotes;
				break;

			case "Payment":
				if (!!this.showSystemNotes === true)
					getUrl = "/payments/getPaymentSystemNotes?paymentId=" + this.referenceId + "&maxnotes=" + this.maxNotes;
				else
					getUrl = "/payments/getPaymentUserNotes?paymentId=" + this.referenceId + "&maxnotes=" + this.maxNotes;
				break;

			case "TimeclockEntry":
				if (!!this.showSystemNotes === true)
					getUrl = "/timeclock/getTimeclockEntrySystemNotes?jobId=" + this.referenceId + "&maxnotes=" + this.maxNotes;
				else
					getUrl = "/timeclock/getTimeclockEntryUserNotes?jobId=" + this.referenceId + "&maxnotes=" + this.maxNotes;
				break;

			case "Task":
				if (!!this.showSystemNotes === true)
					getUrl = "/tasks/getTaskSystemNotes?jobId=" + this.referenceId + "&maxnotes=" + this.maxNotes;
				else
					getUrl = "/timeclock/getTaskUserNotes?jobId=" + this.referenceId + "&maxnotes=" + this.maxNotes;
				break;

			case "Subcontractor":
				if (!!this.showSystemNotes === true)
					getUrl = "/subcontractors/getSubcontractorSystemNotes?subcontractorId=" + this.referenceId + "&maxnotes=" + this.maxNotes;
				else
					getUrl = "/subcontractors/getSubcontractorUserNotes?subcontractorId=" + this.referenceId + "&maxnotes=" + this.maxNotes;
				break;

			case "Vendor":
				if (!!this.showSystemNotes === true)
					getUrl = "/vendors/getVendorSystemNotes?vendorId=" + this.referenceId + "&maxnotes=" + this.maxNotes;
				else
					getUrl = "/vendors/getVendorUserNotes?vendorId=" + this.referenceId + "&maxnotes=" + this.maxNotes;
				break;

			case "WorkOrder":
				if (!!this.showSystemNotes === true)
					getUrl = "/workOrders/getWorkOrderSystemNotes?workOrderId=" + this.referenceId + "&maxnotes=" + this.maxNotes;
				else
					getUrl = "/workOrders/getWorkOrderUserNotes?workOrderId=" + this.referenceId + "&maxnotes=" + this.maxNotes;
				break;
		}

		notes = await this.httpService.get(getUrl);
		if (!!this.showSystemNotes === false) {
			notes = notes.map((note: INoteModel) => {
				return this.processNote(note);
			});
		}

		notes.forEach(note => note.noteSafeHtml = this.sanitizer.bypassSecurityTrustHtml(note.noteHtml));

		this.functionLockService.release("REFRESH_NOTES");

		return Promise.resolve(notes);
	}

	async saveNote(newNoteText: string): Promise<INoteModel> {
		let saveUrl: string = '';
		let params: any = {
			noteText: newNoteText,
			uuid: UtilsService.newGuid()
		};

		switch (this.noteType) {
			case "BusinessDevelopment":
				saveUrl = "/businessDevelopment/saveBusinessDevelopmentUserNote";
				params.businessDevelopmentAccountId = this.referenceId;
				break;

			case "Customer":
				saveUrl = "/customers/saveCustomerUserNote";
				params.customerId = this.referenceId;
				break;

			case "Commission":
				saveUrl = "/commissions/saveCommissionUserNote";
				params.commissionId = this.referenceId;
				break;

			case "Equipment":
				saveUrl = "/equipment/saveEquipmentUserNote";
				params.equipmentId = this.referenceId;
				break;

			case "InvoiceFinancial":
			case "JobFinancial":
				saveUrl = "/jobs/saveFinancialNote";
				params.jobId = this.referenceId;
				break;

			case "GlobalAdmin":
				saveUrl = "/globalAdmin/saveGlobalAdminUserNote";
				params.globalAdminCompanyId = this.referenceId;
				break;

			case "HR":
				saveUrl = "/users/saveHRUserNote";
				params.employeeId = this.referenceId;
				break;

			case "Invoice":
				saveUrl = "/invoices/saveInvoiceUserNote";
				params.invoiceId = this.referenceId;
				break;

			case "Job":
				saveUrl = "/jobs/saveJobUserNote";
				params.jobId = this.referenceId;
				break;

			case "Payable":
				saveUrl = "/payables/savePayableUserNote";
				params.payableId = this.referenceId;
				break;

			case "Payment":
				saveUrl = "/payments/savePaymentUserNote";
				params.paymentId = this.referenceId;
				break;

			case "Subcontractor":
				saveUrl = "/subcontractors/saveSubcontractorUserNote";
				params.subcontractorId = this.referenceId;
				break;

			case "Task":
				saveUrl = "/customers/saveTaskUserNote";
				params.taskId = this.referenceId;
				break;

			case "Vendor":
				saveUrl = "/vendors/saveVendorUserNote";
				params.vendorId = this.referenceId;
				break;

			case "WorkOrder":
				saveUrl = "/workOrders/saveWorkOrderUserNote";
				params.workOrderId = this.referenceId;
				break;
		}

		let newNote: INoteModel = await this.httpService.post(saveUrl, params);
		newNote = this.processNote(newNote);
		newNote.noteSafeHtml = this.sanitizer.bypassSecurityTrustHtml(newNote.noteHtml)

		return Promise.resolve(newNote);
	}

	async updateNote(note: INoteModel): Promise<INoteModel> {
		if (note.noteText === note.unsavedEditText) {
			note.unsavedEditText = null;
			note.editing = false;
			return;
		}

		note.noteText = note.unsavedEditText || note.noteText;
		switch (this.noteType) {
			case "Customer":
				await this.httpService.post("/customers/updateCustomerUserNote", note);
				break;

			case "Commission":
				await this.httpService.post("/commissions/updateCommissionUserNote", note);
				break;

			case "Equipment":
				await this.httpService.post("/equipment/updateEquipmentUserNote", note);
				break;

			case "InvoiceFinancial":
			case "JobFinancial":
				await this.httpService.post("/jobs/updateFinancialNote", note);
				break;

			case "GlobalAdmin":
				await this.httpService.post("/globalAdmin/updateGlobalAdminUserNote", note);
				break;

			case "HR":
				await this.httpService.post("/users/updateHRUserNote", note);
				break;

			case "Invoice":
				await this.httpService.post("/invoices/updateInvoiceUserNote", note);
				break;

			case "Job":
				await this.httpService.post("/jobs/updateJobUserNote", note);
				break;

			case "Payable":
				await this.httpService.post("/receivables/updatePayableUserNote", note);
				break;

			case "Payment":
				await this.httpService.post("/payments/updatePaymentUserNote", note);
				break;

			case "Subcontractor":
				await this.httpService.post("/subcontractors/updateSubcontractorUserNote", note);
				break;

			case "Task":
				await this.httpService.post("/customers/updateTaskUserNote", note);
				break;

			case "Vendor":
				await this.httpService.post("/vendors/updateVendorUserNote", note);
				break;

			case "WorkOrder":
				await this.httpService.post("/workOrder/updateWorkOrderUserNote", note);
				break;
		}
	}

	async deleteNote(note: INoteModel): Promise<void> {
		return this.httpService.delete(`/notes/deleteNote?uuid=${note.uuid}`);
	}

	processNote(note: INoteModel): INoteModel {
		if (note.emailId) {
			note.canEdit = false;
			return note;
		}

		if (note.nonEditable === true) {
			note.canEdit = false;
			return note;
		}

		let noteDate = moment(note.noteDate);
		let expireTime = noteDate.add(15, 'minutes').diff(moment(), "seconds");

		if (expireTime >= 0 && note.userId === GlobalsService.userInfo.userId) {
			note.editTimer = setTimeout(() => {
				note.canEdit = false
			}, expireTime * 1000);
			note.canEdit = true;
		}

		return note;
	}

	async linkNoteToEmail(noteUuid: string, emailUuid: string): Promise<number> {
		let params = {
			noteUuid: noteUuid,
			emailUuid: emailUuid
		}

		return Promise.resolve(await this.httpService.post("/notes/linkEmailToNote", params));
	}

	async getJobNotesByUserId(userId: number, noteCount: number): Promise<INoteModel[]> {
		let params = {
			userId,
			noteCount
		}
		return await this.httpService.get("/notes/getJobNotesByUser", params);
	}

	async getPayableNotesByUserId(userId: number, noteCount: number): Promise<INoteModel[]> {
		let params = {
			userId,
			noteCount
		}
		return await this.httpService.get("/notes/getPayableNotesByUser", params);
	}

	async getCustomerNotesByUserId(userId: number, noteCount: number): Promise<INoteModel[]> {
		let params = {
			userId,
			noteCount
		}
		return await this.httpService.get("/notes/getCustomerNotesByUser", params);
	}

	async getEquipmentNotesByUserId(userId: number, noteCount: number): Promise<INoteModel[]> {
		let params = {
			userId,
			noteCount
		}
		return await this.httpService.get("/notes/getEquipmentNotesByUser", params);
	}

	async getBusinessDevelopmentNotesByUserId(userId: number, noteCount: number): Promise<INoteModel[]> {
		let params = {
			userId,
			noteCount
		}
		return await this.httpService.get("/notes/getBusinessDevelopmentNotesByUser", params);
	}

	async generateNotesPDF(): Promise<string> {
		const params = {
			noteType: this.noteType,
			referenceId: this.referenceId
		}
		const response = await this.httpService.get("/notes/generateNotesPDF", params);
		return response.url;
	}

	async generateNotesEmail(): Promise<IEmailerModel> {
		const params = {
			noteType: this.noteType,
			referenceId: this.referenceId
		}
		return this.httpService.get("/notes/generateNotesEmail", params);

	}
}