export interface IMilestoneDefinitionModel {
	milestoneDefinitionId: number;
	uuid: string;
	sortOrder: number;
	label: string;
	isSystemMilestone: boolean;
	isDeleted: boolean;
}

export class MilestoneDefinitionModel implements IMilestoneDefinitionModel {
	milestoneDefinitionId: number;
	uuid: string;
	sortOrder: number;
	label: string;
	isSystemMilestone: boolean;
	isDeleted: boolean;
}