import { Injectable } from "@angular/core";
import { IDropdownModel, IEmailerModel, IInvoiceModel, InvoiceModel } from "@models";
import { UtilsService } from "./utils/utils.service";
import { HttpService } from "./utils/http.service";
import { IInvoiceLineItemModel, InvoiceLineItemModel } from "../models/invoices/invoice-line-item.model";
import { ISlickFileModel } from "slick-components";

@Injectable()
export class InvoicesService {
	constructor(private httpService: HttpService) { }

	getInvoice(invoiceId: number): Promise<IInvoiceModel> {
		return this.httpService.get("/invoices/getInvoice", { invoiceId: invoiceId });
	}

	getInvoiceForGrid(invoiceId: number): Promise<IInvoiceModel> {
		return this.httpService.get("/invoices/getInvoiceForGrid", { invoiceId: invoiceId });
	}

	getInvoicesForJob(jobId: number): Promise<IInvoiceModel[]> {
		return this.httpService.get("/invoices/getInvoicesForJob", { jobId: jobId });
	}

	addInvoice(invoiceModel: IInvoiceModel): Promise<IInvoiceModel> {
		return this.httpService.post('invoices/addInvoice', invoiceModel);
	}

	updateInvoice(invoiceModel: IInvoiceModel): Promise<IInvoiceModel> {
		return this.httpService.post('invoices/updateInvoice', invoiceModel);
	}

	deleteInvoice(invoiceId: number): Promise<void> {
		return this.httpService.delete(`/invoices/deleteInvoice?invoiceId=${invoiceId}`);
	}

	getActiveJobsForInvoices(): Promise<IDropdownModel[]> {
		return this.httpService.get("/jobs/getActiveJobsForInvoices");
	}

	getNewInvoiceLineItemModel(displaySeq: number): IInvoiceLineItemModel {
		const newModel = new InvoiceLineItemModel();
		newModel.uuid = UtilsService.newGuid();
		newModel.displaySeq = displaySeq;
		newModel.description = '';
		newModel.quantity = 1;
		newModel.amount = 0;
		newModel.isNewLineItem = true;

		return newModel;

	}

	async generateInvoicePDF(invoiceModel: IInvoiceModel): Promise<string> {
		const response = await this.httpService.get("/invoices/generateInvoicePDF", { invoiceId: invoiceModel.invoiceId });
		return response.url;
	}

	async generateInvoiceEmail(invoiceModel: IInvoiceModel): Promise<IEmailerModel> {
		return this.httpService.get("/invoices/generateInvoiceEmail", { invoiceId: invoiceModel.invoiceId });
	}

	async sendInvoiceEmail(invoiceId: number, emailerModel: IEmailerModel): Promise<IEmailerModel> {
		const formData: FormData = new FormData();
		emailerModel.attachments.forEach(file => {
			if (file.base64Image && file.base64Image.length > 0) {
				let fileCopy: ISlickFileModel = UtilsService.clone(file);
				let fileName = file.name;
				delete fileCopy.file;
				delete fileCopy.thumbnailBase64Image;
				let fileJSON = JSON.stringify(fileCopy);
				formData.append('image_' + file.uuid, fileJSON);
			}
			else if (file.file) {
				formData.append('file', file.file, file.file.name);
			}
		})

		let emailerModelTemp: IEmailerModel = UtilsService.clone(emailerModel);
		emailerModelTemp.attachments = emailerModelTemp.attachments.map(att => {
			delete att.file;
			delete att.base64Image;
			delete att.thumbnailBase64Image;
			return att;
		});
		formData.append("emailerModel", JSON.stringify(emailerModelTemp));

		return this.httpService.postMultipart(`/invoices/sendInvoiceEmail?invoiceId=${invoiceId}`, formData);
	}

	async textInvoice(invoiceId: number, toPhoneNumber: string, textBody: string) {
		var params = {
			invoiceId,
			toPhoneNumber,
			textBody
		}
		return this.httpService.post(`/invoices/textInvoice`, params);
	}

}