import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Network, ConnectionStatus, ConnectionType } from '@capacitor/network';

export interface INetworkStatus {
	isNetworkOnline: boolean;
	networkStatus: string;
	isWifi: boolean;
}

export class NetworkStatus implements INetworkStatus {
	isNetworkOnline: boolean;
	networkStatus: string;
	isWifi: boolean;

	constructor(connectionStatus: ConnectionStatus) {
		this.isNetworkOnline = connectionStatus.connected;
		this.networkStatus = connectionStatus.connectionType.toString();
		this.isWifi = (connectionStatus.connectionType === 'wifi');
	}
}

@Injectable()
export class NetworkStatusStore {
	private static _isNetworkOnline: boolean;
	private static _networkStatus: string;
	private static _isWifi: boolean;

	networkStatusChangeSubject: Subject<INetworkStatus> = new Subject<INetworkStatus>();

	constructor() {
	}

	async init() {
		const connectionStatus = await Network.getStatus();
		NetworkStatusStore._isNetworkOnline = connectionStatus.connected;
		NetworkStatusStore._networkStatus = connectionStatus.connectionType.toString();
		NetworkStatusStore._isWifi = (connectionStatus.connectionType === 'wifi');

		const networkStatus = new NetworkStatus(connectionStatus);
		this.networkStatusChangeSubject.next(networkStatus);

		Network.addListener('networkStatusChange', (connectionStatus: ConnectionStatus) => {
			setTimeout(() => {
				NetworkStatusStore._isNetworkOnline = connectionStatus.connected;
				NetworkStatusStore._networkStatus = connectionStatus.connectionType.toString();
				NetworkStatusStore._isWifi = (connectionStatus.connectionType === 'wifi'); 

				const networkStatus = new NetworkStatus(connectionStatus);
				this.networkStatusChangeSubject.next(networkStatus);
			});
		});
	}

	get isOnline(): boolean {
		return NetworkStatusStore._isNetworkOnline;
	}

	get isWifi(): boolean {
		return NetworkStatusStore._isWifi;
	}

	get networkStatus(): string {
		return NetworkStatusStore._networkStatus;
	}

	async refreshCurrentNetworkStatus(broadcast: boolean = false): Promise<NetworkStatus> {
		const connectionStatus = await Network.getStatus();
		NetworkStatusStore._isNetworkOnline = connectionStatus.connected;
		NetworkStatusStore._networkStatus = connectionStatus.connectionType.toString();
		NetworkStatusStore._isWifi = (connectionStatus.connectionType === 'wifi');

		const networkStatus = new NetworkStatus(connectionStatus);

		if (broadcast)
			this.networkStatusChangeSubject.next(networkStatus);

		return networkStatus;
	}

	async broadcastNetworkStatus() {
		const networkStatus = await this.refreshCurrentNetworkStatus();
		this.networkStatusChangeSubject.next(networkStatus);
	}
}
