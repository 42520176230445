export interface IBusinessDevExpenseTypesModel {
	expenseTypeId: number;
	description: string;
	active: boolean;
}

export class BusinessDevExpenseTypesModel implements IBusinessDevExpenseTypesModel {
	expenseTypeId: number;
	description: string;
	active: boolean;
}